import { useEffect, useState } from "react";

import moment from "moment";

import { Form } from "react-bootstrap";

import styles from "./app.module.scss";
import "./App.css";

const timeBeforeDate = (dueDate) => {
  const now = moment();
  const dueDateSplit = dueDate.split(":");

  return (
    moment()
      .set("hours", dueDateSplit[0])
      .set("minutes", dueDateSplit[1])
      .set("seconds", dueDateSplit[2])
      .valueOf() - now.valueOf()
  );
};

const formatTimeLeft = (timeLeft) => {
  const isMinus = timeLeft < 0;

  const seconds = Math.abs(Math.trunc(timeLeft / 1000) % 60);
  const minutes = Math.abs(Math.trunc(timeLeft / (1000 * 60)) % 60);
  const hours = Math.abs(Math.trunc(timeLeft / (1000 * 60 * 60)) % 24);

  return `${isMinus ? "-" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;
};

function App() {
  const [timeLeft, setTimeLeft] = useState(0);
  const [state, setState] = useState({
    dueDate: localStorage.getItem("dueDate") || "23:59:59",
    timeLeft: 0,
  });

  useEffect(() => {
    setTimeLeft(timeBeforeDate(state.dueDate));

    const interval = setInterval(() => {
      setTimeLeft(timeBeforeDate(state.dueDate));
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dueDate]);

  const handleInputChange = (e) => {
    setState({ ...state, dueDate: e.target.value });
    localStorage.setItem("dueDate", e.target.value);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div style={{ color: "#61dafb", fontSize: 72 }}>
          <i className="fas fa-hourglass-half fa-spin"></i>
        </div>
        <p>Timer</p>
        <Form.Control
          // TODO: Move to styles class
          style={{ width: 160, textAlign: "center " }}
          type="time"
          placeholder="Event date"
          name="dueDate"
          value={state.dueDate}
          onChange={handleInputChange}
          step="1"
        />
        <div className={styles.time}>{`${formatTimeLeft(timeLeft)}`}</div>
      </header>
    </div>
  );
}

export default App;
